export const EDUCATION_PURCHASE = '/education/purchase';
export const EDUCATION_PURCHASE_INSTITUTION = '/education/purchase/institution';
export const EDUCATION_PURCHASE_PLAN = '/education/purchase/plan';
export const EDUCATION_PURCHASE_BILLING = '/education/purchase/billing';
export const EDUCATION_PURCHASE_PAYMENT = '/education/purchase/payment';
export const EDUCATION_PURCHASE_ALTERNATIVE_PAYMENT = '/education/purchase/alternative-payment';
export const EDUCATION_PURCHASE_PAYMENT_INFO = '/education/purchase/payment-info';
export const EDUCATION_MIN_SEATS = 5;

export const CLOUD_PURCHASE = '/purchase';
export const CLOUD_PURCHASE_PLAN = '/purchase/plan';
export const CLOUD_PURCHASE_BILLING = '/purchase/billing';
export const CLOUD_PURCHASE_PAYMENT = '/purchase/payment';
export const CLOUD_PURCHASE_PAYMENT_INFO = '/purchase/payment-info';
export const CLOUD_THANK_YOU = '/create/thank-you';

export const JUNIOR_CERTIFICATION_PURCHASE = '/junior-certification/purchase';
export const JUNIOR_CERTIFICATION_PURCHASE_PLAN = '/junior-certification/purchase/plan';
export const JUNIOR_CERTIFICATION_PURCHASE_BILLING = '/junior-certification/purchase/billing';
export const JUNIOR_CERTIFICATION_PURCHASE_PAYMENT = '/junior-certification/purchase/payment';
export const JUNIOR_CERTIFICATION_THANK_YOU = '/create/junior-certification/thank-you';

export const CERTIFICATION_PURCHASE = '/certification/purchase';
export const CERTIFICATION_PURCHASE_EXAM_TYPE = '/certification/purchase/exam-type';
export const CERTIFICATION_PURCHASE_PLAN = '/certification/purchase/plan';
export const CERTIFICATION_PURCHASE_BILLING = '/certification/purchase/billing';
export const CERTIFICATION_PURCHASE_PAYMENT = '/certification/purchase/payment';
export const CERTIFICATION_THANK_YOU = '/create/certification/thank-you';

export const ENTERPRISE_PURCHASE = '/enterprise/purchase';
export const ENTERPRISE_PURCHASE_PLAN = '/enterprise/purchase/plan';
export const ENTERPRISE_PURCHASE_ADDONS = '/enterprise/purchase/addons';
export const ENTERPRISE_PURCHASE_BILLING = '/enterprise/purchase/billing';
export const ENTERPRISE_PURCHASE_PAYMENT = '/enterprise/purchase/payment';
export const ENTERPRISE_PURCHASE_ALTERNATIVE_PAYMENT = '/enterprise/purchase/alternative-payment';

export const CREATE_PATH = '/create';
export const SIM_PATH = '/sim';
export const SUBSCRIPTIONS_PLANS = '/subscriptions/plans';
export const SUBSCRIPTIONS_PATH = '/subscriptions';
export const SUBSCRIPTIONS_REDEEM = '/subscriptions/redeem';
export const AWS_CREATE_PATH = '/aws/awsCreate';

export const SCHOOL_PLAN = 'School Plan';
export const CLOUD_CLASSROOM = 'cloud_classroom';
export const CLOUD_CREATE = 'create';
export const CERTIFICATION_PLAN = 'cert_fundamentals';
export const CERTIFICATION_STARTERKIT = 'certifications_starterkit_bulk';
export const JUNIOR_CERTIFICATION_PRODUCT = 'junior_certification';
export const SIM_CONNECTIVITY = 'sim_connectivity_plan';
export const PRODUCT_SIM = 'sim';
export const ENTERPRISE_PRODUCT = 'cloud_enterprise';
export const ENTERPRISE_AWS_PRODUCT = 'cloud-enterprise';
export const ENTERPRISE_PLAN_MONTHLY = 'cloud_enterprise_monthly';
export const ENTERPRISE_PLAN_YEARLY = 'cloud_enterprise_yearly';

export const OTHER = 'Other';

export const CLASSROOM_OWNER = 'role:edu-owner';
export const CLASSROOM_ADMIN = 'role:edu-admin';
export const CLASSROOM_TEACHER = 'role:edu-teacher';

export const ENTERPRISE_ADMIN = 'role:pro-admin';
export const ENTERPRISE_EDITOR = 'role:pro-editor';

export const FREQUENCY_MONTHLY = 'monthly';
export const FREQUENCY_YEARLY = 'yearly';

export const CREATE_FREE = 'create_free';
export const CREATE_MAKER_MONTHLY = 'create_maker_monthly';
export const CREATE_MAKERPLUS_MONTHLY = 'create_makerplus_monthly';
export const CREATE_ENTRY_YEARLY = 'create_entry_yearly';
export const CREATE_MAKER_YEARLY = 'create_maker_yearly';
export const CREATE_MAKERPLUS_YEARLY = 'create_makerplus_yearly';

export const SPACE_PRIVATE_PLAN = 'maker';
export const SPACE_ENTERPRISE_PLAN = 'pro';
export const SPACE_EDUCATION_PLAN = 'edu';
export const DEFAULT_COMPANY = 'My Company';

export const INQUIRY_THANK_YOU = '/create/inquiry/thank-you';

export const ENTRY_FEATURES = [
  '10 Things',
  'Unlimited dashboards',
  'Unlimited storage for sketches',
  '15 days data retention',
  'Unlimited compilations',
  'LoRaWAN connectivity',
  'APIs',
  'Over the Air Updates',
];
export const MAKER_FEATURES = [
  '25 Things',
  'Unlimited dashboards',
  'Unlimited storage for sketches',
  '3 months data retention',
  'Unlimited compilations',
  'LoRaWAN connectivity',
  'APIs',
  'Over the Air Updates',
  'Dashboard sharing',
];
export const MAKERPLUS_FEATURES = [
  '100 Things',
  'Unlimited dashboards',
  'Unlimited storage for sketches',
  '1 year data retention',
  'Unlimited compilations',
  'LoRaWAN connectivity',
  'APIs',
  'Over the Air Updates',
  'Dashboard sharing',
];

export const ENTERPRISE_PRICE_ADDON_X8_MONTHLY = 5; //Monthly price for each X8 device
export const ENTERPRISE_PRICE_ADDON_X8_YEARLY = 50; //Yearly price for each X8 device

export const ENTERPRISE_PRICE_ADDON3 = 30;
export const ENTERPRISE_PRICE_ADDON4 = 40.5;
export const ENTERPRISE_PRICE_THING_MONTHLY = 1;
export const ENTERPRISE_PRICE_THING_YEARLY = 10;

export const ENTERPRISE_ADDON_X8 = 'cloud_enterprise_board_manager';
export const ENTERPRISE_ADDON_X8_MONTHLY = 'cloud_enterprise_board_manager_monthly';
export const ENTERPRISE_ADDON_X8_YEARLY = 'cloud_enterprise_board_manager_yearly';
export const ENTERPRISE_ADDONS = [
  {
    id: 'cloud_enterprise_board_manager', //_monthly - _yearly
    name: 'Portenta X8 Board Manager',
    description: 'Manage your fleet of devices and ensure the security updates over time',
    hasFeatures: true,
    features: 'How many devices would you use with Foundries.io',
    monthlyPrice: ENTERPRISE_PRICE_ADDON_X8_MONTHLY,
    yearlyPrice: ENTERPRISE_PRICE_ADDON_X8_YEARLY,
    mandatory: false,
  },
];

export const marksEnterpriseThing = [
  {
    value: 50,
    label: '50',
  },
  {
    value: 100,
    label: '100',
  },
  {
    value: 150,
    label: '150',
  },
  {
    value: 200,
    label: '200',
  },
  {
    value: 250,
    label: '250',
  },
  {
    value: 300,
    label: '300',
  },
  {
    value: 350,
    label: '350',
  },
  {
    value: 400,
    label: '400',
  },
  {
    value: 450,
    label: '450',
  },
  {
    value: 500,
    label: '500',
  },
];
