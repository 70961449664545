import React from 'react';
import { Provider } from 'react-redux';
import { Router, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { createBrowserHistory as createHistory } from 'history';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { datadogLogs } from '@datadog/browser-logs';
import PaymentInfoContainer from '../pages/payment-info/PaymentInfoContainer';
import moment from 'moment';
import {
  CREATE_PATH,
  SIM_PATH,
  SUBSCRIPTIONS_PATH,
  SUBSCRIPTIONS_PLANS,
  SUBSCRIPTIONS_REDEEM,
} from '../../utils/utilities';

import createStore from '../../store/createStore';

import PrivateRoute from '../../utils/PrivateRoute';
import ScrollToTop from '../../utils/ScrollToTop';
import { ConfigToast } from '../common/Toast/Toast.jsx';

import { navigationActions } from '../../store/navigation';

import headerFooter from '../../header-footer';

import Home from '../pages/home';
import { Purchase } from '../pages/purchase';
import { PurchaseRedirect } from '../pages/purchaseRedirect';
import { Subscriptions } from '../pages/subscriptions';
import { Aws } from '../pages/aws';
import { productsRouters } from '../../products';
import { PaymentInfo } from '../pages/payment-info';
import packageInfo from '../../../package.json';
import AwsRedeemContainer from '../pages/aws/AwsRedeemContainer';

import {
  BASE_URL,
  APP_NAME,
  APP_DESCRIPTION,
  DRONE_COMMIT,
  DRONE_COMMIT_BRANCH,
  DRONE_BUILD_STARTED,
  DRONE_BUILD_NUMBER,
  NODE_ENV,
  DATADOG_TOKEN,
} from '../../env';

import './App.scss';
import AwsInfo from '../pages/aws/AwsInfo';

const history = createHistory({
  basename: BASE_URL,
});

history.listen(() => {
  if (window.Intercom && window.Intercom.booted) {
    window.Intercom('update');
  }
});

const store = createStore();

// Reads the source app and redirect URL from query params and puts them into Redux
store.dispatch(navigationActions.addSource());

if (DRONE_BUILD_NUMBER) {
  if (NODE_ENV !== 'production') {
    /* eslint-disable no-console */
    console.info('Arduino Digital Store - Version ${packageInfo.version}');
    console.info(`Build number ${DRONE_BUILD_NUMBER} started on ${moment.unix(DRONE_BUILD_STARTED).format()}`);
    console.info(`Commit ${DRONE_COMMIT} on branch "${DRONE_COMMIT_BRANCH}"`);
    /* eslint-enable no-console */
  }

  window.buildInfo = {
    buildNumber: DRONE_BUILD_NUMBER,
    buildStarted: moment.unix(DRONE_BUILD_STARTED).format(),
    commit: DRONE_COMMIT,
    commitBranch: DRONE_COMMIT_BRANCH,
    version: packageInfo.version,
  };
}

// eslint-disable-next-line no-undef
window.env = process.env;

if (DATADOG_TOKEN) {
  datadogLogs.init({
    clientToken: DATADOG_TOKEN,
    forwardErrorsToLogs: false,
  });
}

window.onerror = (msg, url, lineNo, columnNo, error) => {
  DATADOG_TOKEN &&
    datadogLogs.logger.log(
      msg,
      {
        app: 'digital_store',
        buildNumber: DRONE_BUILD_NUMBER,
        env: NODE_ENV,
        stacktrace: error,
        source: `url: ${url}, line number: ${lineNo}, column number: ${columnNo}`,
      },
      'error'
    );
};

// Initialize ToastContainer
ConfigToast();

function useHeaderFooter() {
  const location = useLocation();
  React.useEffect(() => {
    headerFooter(location.pathname);
  }, [location]);
}

function HeaderFooterApp() {
  useHeaderFooter();
  return null;
}

const App = () => {
  const productsRoutes = Object.keys(productsRouters).map((routePath) => {
    const ProductRouter = productsRouters[routePath];
    return <Route key={routePath} path={`/${routePath}`} component={ProductRouter} />;
  });

  /*
   * We want to be redirected to last checkoutflow visited after login.
   * Since the RedirectAfterLogin component was broken for something related to a very old router,
   * we copy the logic here.
   * The logic is to save the last visited path. If the path exists, we delete the save and redirect to that checkout flow.
   * Otherwise, we save the path.
   */
  React.useEffect(() => {
    window.arduinoHeader.init().then((auth) => {
      if (auth.previousState && auth.previousState.pathname) {
        history.push(auth.previousState.pathname);
      } else {
        if (
          localStorage.getItem('path') === CREATE_PATH ||
          localStorage.getItem('path') === SIM_PATH ||
          localStorage.getItem('path') === SUBSCRIPTIONS_PATH
        ) {
          localStorage.removeItem('path');
        }
        if (
          (localStorage.getItem('path') === null || localStorage.getItem('path') === '/') &&
          location.pathname !== '/' &&
          location.pathname !== '/aws'
        ) {
          localStorage.setItem('path', location.pathname);
        } else {
          if (
            localStorage.getItem('path') !== SUBSCRIPTIONS_REDEEM &&
            localStorage.getItem('path') !== SUBSCRIPTIONS_PLANS
          ) {
            localStorage.removeItem('path');
          }
        }
        if (
          localStorage.getItem('path') !== null &&
          localStorage.getItem('path') !== '/' &&
          localStorage.getItem('path') !== '/aws' &&
          auth.isAuthenticated
        ) {
          history.push(localStorage.getItem('path') + window.location.search);
        }
      }
    });

    //debugger;
  }, []);

  return (
    <HelmetProvider>
      <Helmet>
        {/* Default metadata - can be overridden in nested components */}
        <title>{APP_NAME}</title>
        <meta name="theme-color" content="#f1c40f" />
        <meta name="description" content={APP_DESCRIPTION} />
      </Helmet>
      <Provider store={store}>
        <Router history={history}>
          <HeaderFooterApp />
          <ScrollToTop>
            <div className="app">
              <Switch>
                <Route exact path="/" component={Home} />
                {productsRoutes}

                {/* Someone shared the wrong URL on the web, so we have to patch it with a redirect  and redirect to the new url*/}
                <Route exact path="/cert_fundamentals" render={() => <Redirect to={`/certification`} />} />
                <Route exact path="/cert-fundamentals" render={() => <Redirect to={`/certification`} />} />

                <Route exact path="/education" render={() => <Redirect to={`/education/purchase`} />} />
                <Route exact path="/certification" render={() => <Redirect to={`/certification/purchase`} />} />

                <Route exact path="/enterprise" render={() => <Redirect to={`/enterprise/purchase`} />} />

                <PrivateRoute path="/purchase" component={Purchase} />
                <PrivateRoute path="/education/purchase" component={Purchase} />
                <PrivateRoute path="/certification/purchase" component={Purchase} />
                <PrivateRoute path="/enterprise/purchase" component={Purchase} />
                <PrivateRoute path="/payment-info" component={PaymentInfo} />
                <PrivateRoute path="/subscriptions" component={Subscriptions} />

                <Route path="/aws" component={Aws} />
                <Route path="/aws-info" component={AwsInfo} />
                <PrivateRoute path="/aws/awsCreate" component={AwsRedeemContainer} />

                <Route path={`/payment-info`} component={PaymentInfoContainer} />
                <Route path="/purchase-redirect/:productId/:planId" component={PurchaseRedirect} />

                <Route render={() => <Redirect to="/" />} />
              </Switch>
            </div>
          </ScrollToTop>
        </Router>
      </Provider>
    </HelmetProvider>
  );
};

export default App;
